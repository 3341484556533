<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Custom Push Notifications'">
          <template v-slot:preview>
            <form ref="form">
              <div
                class="col-md-12 p-0"
                v-if="userType === 'System Administrator'"
              >
                <div class="form-group">
                  <label>City</label>
                  <div>
                    <vue-google-autocomplete
                      ref="address"
                      id="map"
                      :country="['uk', 'gb']"
                      @change="getRestaurants()"
                      v-model="cityName"
                      classname="form-control"
                      placeholder="Enter Town"
                      types="(cities)"
                      v-on:placechanged="getAddressData"
                    >
                    </vue-google-autocomplete>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 p-0"
                v-show="
                  userType === 'System Administrator' && showRestaurantListing
                "
              >
                <div class="form-group">
                  <label>Restaurant</label>
                  <div>
                    <select
                      v-model="restaurant"
                      @change="getUsers()"
                      required
                      class="form-control-role"
                    >
                      <option value="">Select Restaurant</option>
                      <option
                        v-for="(restaurant, index) in restaurantListing"
                        :value="restaurant.uuid"
                        v-bind:key="index"
                        :title="restaurant.title"
                      >
                        {{ restaurant.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!--              <div-->
              <!--                class="col-md-12 p-0"-->
              <!--                v-show="userType === 'System Administrator' && showRadius"-->
              <!--              >-->
              <!--                <div class="form-group">-->
              <!--                  <label>Radius</label>-->
              <!--                  <div>-->
              <!--                    <select-->
              <!--                      :disabled="disableRadius"-->
              <!--                      v-model="radius"-->
              <!--                      @change="getUsers()"-->
              <!--                      required-->
              <!--                      class="form-control-role"-->
              <!--                    >-->
              <!--                      <option value="">Select Radius</option>-->
              <!--                      <option value="1">1 Mile</option>-->
              <!--                      <option value="2">2 Mile</option>-->
              <!--                      <option value="3">3 Mile</option>-->
              <!--                      <option value="4">4 Mile</option>-->
              <!--                      <option value="5">5 Mile</option>-->
              <!--                      <option value="6">6 Mile</option>-->
              <!--                      <option value="7">7 Mile</option>-->
              <!--                      <option value="8">8 Mile</option>-->
              <!--                      <option value="9">9 Mile</option>-->
              <!--                      <option value="10">10 Mile</option>-->
              <!--                    </select>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->

              <div class="col-md-12 p-0">
                <div class="form-group">
                  <b-form-group label="All Users">
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <b-form-radio
                        @change="selectUsersType()"
                        v-model="all_users"
                        name="some-radios"
                        value="0"
                      >
                        No
                      </b-form-radio>
                      <b-form-radio
                        @change="selectUsersType()"
                        v-model="all_users"
                        name="some-radios"
                        value="1"
                        class="ml-3"
                      >
                        Yes
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="col-md-12 p-0" v-if="is_all" v-show="showUsers">
                <div class="form-group users-notification-wrapper">
                  <label for="users">Users</label>
                  <select2-multiple-control
                    id="users"
                    class="form-control-role"
                    v-model="users"
                    :options="myOptions"
                    @change="myChangeEvent($event)"
                    @select="mySelectEvent($event)"
                  />
                  <p v-if="error" style="color: red">Please Select Users</p>
                </div>
              </div>
              <div class="col-md-12 p-0">
                <div class="form-group">
                  <label for="title">Title</label>
                  <input
                    required
                    type="text"
                    v-model="title"
                    class="form-control form-control-solid"
                    id="title"
                    placeholder="Enter title here..."
                  />
                </div>
              </div>
              <div class="col-md-12 p-0">
                <div class="form-group">
                  <label for="message">Custom Message</label>
                  <textarea
                    required
                    v-model="message"
                    class="form-control form-control-solid"
                    id="message"
                    placeholder="Enter description here..."
                    rows="10"
                    cols="10"
                  ></textarea>
                </div>
                <div class="text-right">
                  <button
                    :disabled="isFormValid"
                    type="button"
                    class="btn custom-btn-style"
                    @click="sendNotification()"
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import ApiService from "@/core/services/api.service";
import VueGoogleAutocomplete from "vue-google-autocomplete";

// import $ from "jquery";
import Select2MultipleControl from "v-select2-multiple-component";
import helpers from "../../helpers";

export default {
  data() {
    return {
      cityName: "",
      disabled: false,
      restaurant_uuid: "",
      error: false,
      loader: false,
      all_users: 0,
      is_all: true,
      title: "",
      message: "",
      usersListing: [],
      restaurantListing: [],
      users: "",
      userType: "",
      showRestaurantListing: false,
      showRadius: false,
      showUsers: false,
      disableRadius: true,
      code1: {
        html: ``,
        singleSelect: false,
        selected: []
      },
      myValue: "",
      myOptions: [],
      cities: [],
      radius: 0,
      restaurant: ""
    };
  },
  components: {
    KTCodePreview,
    Select2MultipleControl,
    VueGoogleAutocomplete
  },
  computed: {
    isFormValid() {
      return !(this.title !== "" && this.message !== "");
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    getAddressData: function(addressData) {
      this.cityName = addressData.locality;
    },
    myChangeEvent() {},
    mySelectEvent() {},
    selectUsersType() {
      if (this.all_users == 1) {
        this.is_all = false;
        this.disabled = false;
      } else {
        this.is_all = true;
      }
    },
    getUsers() {
      this.showUsers = true;
      this.disableRadius = false;
      this.loader = true;
      let restaurant_uuid = "";
      if (this.userType === "System Administrator") {
        restaurant_uuid = this.restaurant;
        this.restaurant_uuid = restaurant_uuid;
      } else {
        restaurant_uuid = this.restaurant;
      }
      var data = {
        city: this.cityName,
        distance: this.radius,
        restaurant_uuid: restaurant_uuid
      };
      ApiService.post(this.$path + "/admin/consumer/list", data)
        .then(({ data }) => {
          this.showRadius = true;
          this.usersListing = data.data;
          this.myOptions = this.usersListing.map(function(user) {
            return {
              id: user.uuid,
              text: user.name
            };
          });
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getRestaurants() {
      this.loader = true;
      var data = {
        city: this.cityName
      };
      ApiService.post(this.$path + "/admin/restaurant/list", data)
        .then(({ data }) => {
          this.showRestaurantListing = true;
          this.restaurantListing = data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    sendNotification() {
      let users = [];
      if (this.all_users == 1) {
        this.usersListing.map(function(user) {
          users.push({
            uuid: user.uuid
          });
        });
      } else {
        if (this.users.length > 0) {
          users = this.users.map(function(user) {
            return {
              uuid: user
            };
          });
          this.disabled = false;
        } else {
          this.error = true;
          this.disabled = true;
        }
      }
      let data = {
        title: this.title,
        message: this.message,
        users: users
      };
      ApiService.post(
        this.$path +
          "/admin/restaurant/custom/notifications/" +
          this.restaurant_uuid,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
        .then(() => {
          this.$bvToast.toast("Notification Send Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
            toaster: "b-toaster-top-center"
          });
          this.getRestaurantTables();
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getCities() {
      this.cities = [
        { city: "Birmingham" },
        { city: "Leeds" },
        { city: "Sheffield" },
        { city: "Bradford" },
        { city: "Manchester" },
        { city: "Liverpool" },
        { city: "Bristol" },
        { city: "Leicester" },
        { city: "Wakefield" },
        { city: "Coventry" },
        { city: "Nottingham" },
        { city: "Newcastle upon Tyne" },
        { city: "Sunderland" },
        { city: "Brighton & Hove" },
        { city: "Kingston upon Hull" },
        { city: "Plymouth" },
        { city: "Wolverhampton" },
        { city: "Stoke-on-Trent" },
        { city: "Derby" },
        { city: "Isle-of-Wight" },
        { city: "Southampton" },
        { city: "Salford" },
        { city: "City of Westminster" },
        { city: "Portsmouth" },
        { city: "York" },
        { city: "Peterborough" },
        { city: "Chelmsford" },
        { city: "Oxford" },
        { city: "Canterbury" },
        { city: "St Albans" },
        { city: "Preston" },
        { city: "Lancaster" },
        { city: "Norwich" },
        { city: "Cambridge" },
        { city: "Gloucester" },
        { city: "Exeter" },
        { city: "Winchester" },
        { city: "Carlisle" },
        { city: "Worcester" },
        { city: "Durham" },
        { city: "Lincoln" },
        { city: "Chester" },
        { city: "Bath" },
        { city: "Hereford" },
        { city: "Salisbury" },
        { city: "Lichfield" },
        { city: "Chichester" },
        { city: "Ely" },
        { city: "Truro" },
        { city: "Ripon" },
        { city: "Wells" },
        { city: "City Of London" }
      ];
    }
  },
  mounted() {
    this.getCities();
    this.userType = JSON.parse(
      localStorage.getItem("currentUser")
    ).roles[0].title;

    if (this.userType !== "System Administrator") {
      this.restaurant_uuid = JSON.parse(
        localStorage.getItem("currentUser")
      ).restaurant.uuid;
    }
  },
  watch: {
    users() {
      if (this.users.length === 0) {
        this.error = true;
        this.disabled = true;
      } else {
        this.error = false;
        this.disabled = false;
      }
      if (this.all_users === 1) {
        this.disabled = false;
      }
    }
  }
};
</script>
<style scoped>
/*.loading-spinner {*/
/*  display: none;*/
/*}*/
</style>
